// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader60001: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: white,
      },
    },
    LogoBrand: {
      logoLink: {
        height: 32,
        marginLeft: margins.lg,
      },
      logoWrapper: {
        '@media screen and (max-width: 1200px)': {
          width: 240,
        },
      },
    },
  };
};
