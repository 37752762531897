// @flow
import { type JSSThemeDefaultBonusesType } from '../JSSThemeDefaultBonuses';

export const JSSThemeBonuses60001: JSSThemeDefaultBonusesType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { background1, warning, text5, text4, white },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      bonusContainer: {
        background: background1,
        border: 'none',
      },
      tabsItemDesktop: {
        background: warning,
        color: white,
        transition: '.3s ease-in',
        fontWeight: 'bold',
        '&:hover, &.active': {
          background: text5,
        },
      },
      tabsItemMobile: {
        color: white,
        background: warning,
        '&.active': {
          background: text5,
        },
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
    },
    Bonuses: {
      root: {
        border: 'none',
      },
      description: {
        color: text4,
      },
      headingSmall: {
        color: warning,
      },
      rootRight: {
        color: ' #4E5A6C',
      },
    },
  };
};
