// @flow
import { type JSSThemeDefaultAffiliatesType } from '../JSSThemeDefaultAffiliates';

export const JSSThemeAffiliates60001: JSSThemeDefaultAffiliatesType = (
  themeVars
) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { white },
    },
  } = themeVars;

  return {
    root: {
      background: white,
    },
    text: {
      margin: margins.md,
    },
    affiliatesHeading: {
      color: '#FFF',
      fontWeight: '800 !important',
      fontSize: 72,
      lineHeight: '80px',
      padding: '70px 20px 50px',
      margin: '30px auto 0',
      maxWidth: 1216,
      background: 'transparent',
      '@media (pointer: coarse)': {
        fontSize: 48,
        padding: '50px 15px 30px',
        margin: '0 auto',
        lineHeight: '58px',
      },
    },
    affiliateHeader: {
      headerRoot: {
        background: 'transparent',
      },
      loginBar: {
        maxWidth: 1216,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: [margins.lg, margins.lg],
        '@media (pointer: coarse)': {
          justifyContent: 'flex-start',
          padding: '40px 0 0 60px',
          flexDirection: 'column',
        },
      },
      reasonsIcon: {
        fill: white,
        marginRight: '25px',
        minWidth: '35px',
      },
      SignupIcon: {
        marginRight: margins.md,
        fill: '#454C4D',
        opacity: '0.8',
        '@media (pointer: coarse)': {
          fill: white,
        },
      },
      loginBarRoot: {
        zIndex: '3',
        position: 'relative',
        background: '#C0A988',
        '@media (pointer: coarse)': {
          background: 'transparent',
        },
      },
      SignupRoot: {
        marginRight: 40,
        display: 'flex',
        opacity: 1,
        color: '#454C4D',
        alignItems: 'center',
        '&:last-child': {
          marginRight: 0,
        },
        '&:hover': {
          opacity: '.7',
          color: '#454C4D',
        },
        '@media (pointer: coarse)': {
          padding: '0 0 30px',
          color: white,
        },
      },
      logo: {
        height: 40,
        width: 'auto',
        marginLeft: margins.lg,
        '@media (pointer: coarse)': {
          marginLeft: margins.xm,
          height: 32,
        },
      },
      topBlock: {
        maxWidth: 1216,
        margin: '30px auto 0',
        display: 'flex',
        flexDirection: 'row',
        zIndex: 3,
        position: 'relative',
        justifyContent: 'space-between',
        '@media (pointer: coarse)': {
          margin: [margins.lg, 'auto', 0],
          zIndex: 'auto',
        },
      },
      topMenuLink: {
        marginLeft: 40,
        fontSize: fontSizes.lg,
        fontWeight: 'bold',
        '@media (pointer: coarse)': {
          marginBottom: '30px',
          marginLeft: 0,
        },
      },
      topMenuContainer: {
        marginTop: margins.md,
        display: 'flex',
        marginRight: margins.lg,
        '@media (pointer: coarse)': {
          flexDirection: 'column',
          padding: '80px 0 0 60px',
        },
      },
      topMenuItem: {
        color: white,
        '&:hover': {
          color: '#C0A988',
        },
      },
      topMenuItemActive: {
        color: '#C0A988 !important',
      },
      burger: {
        marginRight: margins.xm,
        position: 'absolute',
        top: 5,
        right: 0,
      },
      cross: {
        position: 'absolute',
        right: '0px',
        top: '22px',
        width: '50px',
        height: '50px',
        '&::before': {
          transform: 'rotate(45deg)',
          position: 'absolute',
          left: 18,
          content: '""',
          height: 29,
          width: 2,
          backgroundColor: '#fff',
        },
        '&::after': {
          transform: 'rotate(-45deg)',
          position: 'absolute',
          left: 18,
          content: '""',
          height: 29,
          width: 2,
          backgroundColor: '#fff',
        },
      },
      burgerOverlay: {
        position: 'fixed',
        background: '#454c4d',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        zIndex: 999,
        overflowY: 'scroll',
      },
      burgerBar: {
        width: 25,
        height: 2,
        background: '#fff',
        marginBottom: '7px',
        display: 'block',
        borderRadius: 2,
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    affiliatesFooter: {
      footerRoot: {
        background: '#C0A988',
      },
      footer: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: ['50px', margins.lg, '50px'],
        fontSize: fontSizes.lg,
        display: 'flex',
        justifyContent: 'space-between',
        color: white,
        opacity: '1',
        '@media (pointer: coarse)': {
          flexDirection: 'column',
        },
      },
      productsTerms: {
        fontWeight: 'bold',
        opacity: '1',
        textDecoration: 'underline',
        color: white,
        '&:hover': {
          color: white,
          opacity: '.7',
        },
        '@media (pointer: coarse)': {
          paddingBottom: margins.lg,
        },
      },
    },
    affiliatesHome: {
      affiliateRoot: {
        background: '#454c4d',
        color: '#fff',
        opacity: '1',
        fontFamily: 'Inter, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
      },
      aflLink: {
        color: '#a88c68',
        fontWeight: 'bold',
        '&:hover': {
          color: '#C0A988',
        },
      },
      brandsRoot: {
        background: '#454C4D',
        paddingBottom: '130px',
        '@media screen and (max-width:790px)': {
          paddingBottom: '60px',
        },
      },
      brandItemsRoot: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: ['0', margins.lg],
      },
      brandItems: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      brandImg: {
        borderRadius: '30px',
        maxWidth: '170px',
        marginRight: margins.lg,
        '@media screen and (max-width:1190px)': {
          maxWidth: '150px',
        },
        '@media screen and (max-width:939px)': {
          maxWidth: '130px',
        },
        '@media screen and (max-width:790px)': {
          marginBottom: margins.lg,
        },
      },
      centerContent: {
        maxWidth: 1216,
        margin: '0 auto',
        background: '#C0A988',
      },
      topBannerHeader: {
        fontWeight: '800',
        fontSize: 72,
        lineHeight: '80px',
        maxWidth: 500,
        '@media (pointer: coarse)': {
          fontSize: 30,
          lineHeight: '30px',
          maxWidth: 300,
          paddingLeft: margins.xm,
        },
      },
      topBanner: {
        minHeight: 600,
        maxWidth: 1216,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: [0, margins.lg, '60px'],
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 0px',
        backgroundSize: 610,
        position: 'relative',
        '@media (pointer: coarse)': {
          padding: 0,
          minHeight: 'auto',
        },
      },
      topBannerRoot: {
        zIndex: '3',
        position: 'relative',
        '@media screen and (max-width:1216px)': {
          padding: [0, 0, 0, margins.md],
        },
        '@media (pointer: coarse)': {
          padding: [0, 0, margins.md],
        },
      },
      topBannerButton: {
        textShadow: 'none',
        backgroundColor: '#C0A988',
        padding: '16px 50px',
        borderRadius: 30,
        fontSize: fontSizes.xl,
        margin: [margins.lg, 0, '90px'],
        color: '#fff',
        display: 'inline-block',
        alignSelf: 'flex-start',
        border: 'none',
        fontWeight: 'bold',
        background: '#C0A988',
        transition: '.3s ease-out',
        boxShadow: 'none',
        '@media (pointer: coarse)': {
          margin: ['4px', 0, 0, margins.xm],
        },
        '&:hover': {
          background: '#a88c68',
          backgroundColor: '#a88c68',
          boxShadow: 'none',
        },
        '&:disabled:hover': {
          backgroundColor: '#C0A988',
          background: '#C0A988',
          boxShadow: 'none',
        },
      },
      topBannerDescription: {
        fontSize: fontSizes.xl,
        margin: '25px 0',
        lineHeight: '28px',
        '@media (pointer: coarse)': {
          margin: [margins.xm, 0, margins.md],
          maxWidth: 200,
          marginLeft: margins.xm,
        },
      },
      stepsRoot: {
        display: 'flex',
        flexDirection: 'row',
        margin: [0, margins.xm],
        position: 'relative',
        zIndex: 3,
        '@media (pointer: coarse)': {
          flexDirection: 'column',
        },
      },
      step: {
        background: '#fff',
        width: '33%',
        borderRadius: 30,
        padding: '50px 50px 45px',
        marginRight: '30px',
        marginTop: '-60px',
        marginBottom: '30px',
        boxShadow: '0px 8px 21px 14px rgb(34 85 164 / 6%)',
        '&:last-child': {
          marginRight: 0,
        },
        '&:first-child': {
          marginTop: '-60px',
        },
        '@media (pointer: coarse)': {
          width: '100%',
          marginRight: '0px',
          marginTop: '0px',
          padding: '35px',
        },
      },
      stepDescription: {
        color: '#041A3D',
        fontSize: fontSizes.lg,
        lineHeight: '24px',
        opacity: '.6',
        padding: [margins.lg, 0, 0],
      },
      stepHeading: {
        color: '#454c4d',
        fontWeight: 800,

        fontSize: fontSizes.xxxl,
        paddingLeft: '20px',
      },
      stepHeadingRoot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      stepNumber: {
        fontSize: '24px',
        background: '#C0A988',
        fontWeight: 800,

        borderRadius: '50px',
        alignSelf: 'flex-start',
        width: '45px',
        height: '45px',
        lineHeight: '45px',
        textAlign: 'center',
        color: white,
      },
      contentRoot: {
        padding: [0, margins.lg],
      },
      centerContentRoot: {
        color: white,
        background: '#C0A988',
      },
      contentHeading: {
        color: white,
        fontWeight: 800,

        fontSize: 48,
        lineHeight: '58px',
        padding: '120px 0 60px',
        '@media (pointer: coarse)': {
          padding: '50px 0',
        },
      },
      reasonsItemRoot: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 80,
        flexWrap: 'wrap',
        '@media (pointer: coarse)': {
          flexDirection: 'column',
          paddingBottom: 40,
        },
      },
      reasonsItem: {
        alignSelf: 'flex-start',
        width: '33%',
        padding: '0 50px 40px 0',
        fontSize: fontSizes.lg,
        display: 'flex',
        color: white,
        '@media (pointer: coarse)': {
          width: '100%',
        },
      },
      productsRoot: {
        background: white,
        color: '#454C4D',
        padding: [0, margins.lg],
      },
      products: {
        maxWidth: 1216,
        margin: '0 auto',
        display: 'flex',
      },
      productsImg: {
        padding: '100px 70px 30px 0',
        '@media (pointer: coarse)': {
          display: 'none',
        },
      },
      productsHeading: {
        color: '#454C4D',
        fontWeight: 800,

        fontSize: 48,
        lineHeight: '48px',
        padding: '120px 0 50px',
        margin: 0,
        '@media (pointer: coarse)': {
          padding: '80px 0 50px',
        },
      },
      productsList: {
        color: '#454C4D',
        opacity: '.6',
        fontSize: fontSizes.lg,
        marginLeft: margins.lg,
      },
      productsListItem: {
        marginBottom: '28px',
      },
      productsButton: {
        textShadow: 'none',
        backgroundColor: '#C0A988',
        padding: '16px 50px',
        borderRadius: 30,
        fontSize: fontSizes.xl,
        margin: [margins.lg, 0, '90px'],
        color: '#fff',
        display: 'inline-block',
        alignSelf: 'flex-start',
        border: 'none',
        fontWeight: 'bold',
        background: '#C0A988',
        transition: '.3s ease-out',
        boxShadow: 'none',
        '&:hover': {
          background: '#a88c68',
          backgroundColor: '#a88c68',
          boxShadow: 'none',
        },
        '&:disabled:hover': {
          backgroundColor: '#C0A988',
          background: '#C0A988',
          boxShadow: 'none',
        },
      },
    },
    affiliatesSignup: {
      signupRoot: {},
      signupHeader: {
        color: '#FFF',
        fontWeight: 800,

        fontSize: 72,
        lineHeight: '80px',
        padding: '70px 0 50px',
        margin: '30px auto 0',
        maxWidth: 1216,
      },
      signupIframe: {
        maxWidth: 1216,
        margin: '0 auto',
      },
      signupIframeWrapper: {
        background: '#fff',
        color: '#041A3D',
        display: 'flex',
      },
    },
    affiliatesCommission: {
      commissionHeader: {
        color: '#FFF',
        fontWeight: 800,

        fontSize: 72,
        lineHeight: '80px',
        padding: '70px 0 50px',
        margin: '30px auto 0',
        maxWidth: 1216,
      },
      commissionContentWrapper: {
        background: '#fff',
      },
      commissionContent: {
        maxWidth: 1216,
        margin: '0 auto',
        color: '#041A3D',
        padding: ['100px', margins.lg],
      },
      commissionTable: {
        border: '1px solid rgba(4,26,61, .1)',
        color: '#454c4d',
        padding: [0, 0, margins.md],
        borderRadius: 20,
        overflow: 'hidden',
        marginBottom: '50px',
      },
      commissionTableHeading: {
        color: '#454c4d',
        fontWeight: 'bold',
        background: 'rgba(4,26,61, .1)',
        padding: margins.lg,
      },
      commissionTableCell: {
        padding: [margins.lg, margins.lg, margins.md],
        background: white,
      },
      commissionSubHeader: {
        fontWeight: 800,
        color: '#454c4d',
        fontSize: 36,
        margin: 0,
        maxWidth: 820,
        padding: '50px 0 25px',
      },
      commissionText: {
        fontSize: fontSizes.xl,
        lineHeight: '28px',
        maxWidth: 820,
        color: '#454c4d',
        // padding: [0, 0, '25px'],
        margin: 0,
      },
    },
    affiliatesProducts: {
      productsHeader: {
        color: '#FFF',
        fontWeight: 800,

        fontSize: 72,
        lineHeight: '80px',
        padding: '70px 0 50px',
        margin: '30px auto 0',
        maxWidth: 1216,
      },
      productsContentWrapper: {
        background: '#fff',
        color: '#041A3D',
      },
      productsContent: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: ['25px', margins.lg, '50px'],
      },
      productsSignupText: {
        marginRight: margins.md,
      },
      productsSubHeader: {
        fontWeight: '800 !important',
        fontSize: 48,
        padding: '75px 0 50px',
        color: '#454c4d',
        margin: 0,
      },
      productsText: {
        opacity: '.6',
        fontSize: fontSizes.xl,
        lineHeight: '28px',
        maxWidth: 820,
        padding: [0, 0, '25px'],
        margin: 0,
      },
      productsButtonBlock: {
        fontSize: fontSizes.lg,
        color: '#454c4d',
      },
      productsButton: {
        textShadow: 'none',
        backgroundColor: '#C0A988',
        padding: '16px 50px',
        borderRadius: 30,
        fontSize: fontSizes.xl,
        marginRight: margins.md,
        margin: [margins.lg, 0, '90px'],
        color: '#fff',
        display: 'inline-block',
        alignSelf: 'flex-start',
        border: 'none',
        fontWeight: 'bold',
        background: '#C0A988',
        transition: '.3s ease-out',
        boxShadow: 'none',
        '&:hover': {
          background: '#a88c68',
          backgroundColor: '#a88c68',
          boxShadow: 'none',
        },
        '&:disabled:hover': {
          backgroundColor: '#C0A988',
          background: '#C0A988',
          boxShadow: 'none',
        },
        '@media (pointer: coarse)': {
          margin: ['25px', '20px', '20px', 0],
          width: '100%',
          textAlign: 'center',
        },
      },
    },
    affiliatesTestimonals: {
      testimonalsRoot: {},
      testimonalsWrapper: {
        background: '#FFF',
      },
      testimonalsContent: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: '100px 20px',
        '@media (pointer: coarse)': {
          padding: [margins.xl, margins.xm],
        },
      },
      testimonalsTextWrapper: {
        color: '#454C4D',
      },
      testimonalsText: {
        opacity: 0.6,
        margin: [margins.xs, 0, margins.lg],
      },
      testimonalsLink: {
        color: '#a79172',
        '&:hover': {
          color: '#a88c68',
        },
      },
      testimonalsItem: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: margins.lg,
      },
      testimonalsImg: {
        marginRight: margins.lg,
        '@media (pointer: coarse)': {
          maxWidth: '60px',
          height: 'auto',
        },
      },
    },
    affiliatesContacts: {
      contactsHeader: {
        color: '#FFF',
        fontWeight: 800,

        fontSize: 72,
        lineHeight: '80px',
        padding: '70px 0 50px',
        margin: '30px auto 0',
        maxWidth: 1216,
      },
      contactsContentWrapper: {
        background: '#FFF',
      },
      contactsText: {
        fontSize: fontSizes.xl,
        lineHeight: '28px',
        maxWidth: 820,
        color: '#454c4d',
        padding: [0, 0, '25px'],
        margin: 0,
      },
      contactsContent: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: '100px 20px',
      },
      contactsMail: {
        color: '#454c4d',
        fontWeight: 'bold',
        display: 'inline-block',
        '&:hover': {
          color: '#a88c68',
        },
      },
    },
    affiliatesFAQ: {
      FAQHeader: {
        color: '#FFF',
        fontWeight: 800,

        fontSize: 72,
        lineHeight: '80px',
        padding: '70px 0 50px',
        margin: '30px auto 0',
        maxWidth: 1216,
      },
      FAQContentWrapper: {
        background: '#FFF',
      },
      FAQContent: {
        margin: '0 auto',
        maxWidth: 1216,
        padding: '50px 20px',
        color: '#454c4d',
      },
      FAQquestion: {
        fontWeight: 800,

        fontSize: 36,
        margin: 0,
        maxWidth: 820,
        padding: '50px 0 15px',
      },
      FAQText: {
        fontSize: fontSizes.lg,
        opacity: '.6',
        maxWidth: 820,
        lineHeight: '26px',
      },
    },
  };
};
