// @flow
import { type JSSThemeDefaultBalanceType } from '../JSSThemeDefaultBalance';

export const JSSThemeBalance60001: JSSThemeDefaultBalanceType = (themeVars) => {
  const {
    margins,
    borderRadius,
    colors: {
      colorTypes: {
        white,
        background1,
        black,
        shadow,
        primary,
        warning,
        text4,
      },
    },
  } = themeVars;
  return {
    BalanceComponent: {
      titleContent: {
        padding: [margins.xs, margins.md],
        background: white,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
        color: primary,
        fontWeight: 'bold',
      },
      userMenuWrap: {
        padding: 0,
        background: background1,
        fontWeight: 'normal',
        '&:before': {
          background: background1,
        },
      },
      accountMenuWrap: {
        borderBottom: `1px solid ${shadow}`,
        borderTop: `1px solid ${shadow}`,
        margin: '10px',
      },
      balanceWrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: '5px',
      },
      logoutWrap: {
        color: text4,
        fontWeight: 'normal',
        padding: [0, margins.md, margins.md, margins.md],
        '&:hover': {
          opacity: 0.7,
        },
      },
    },
    UsernameCompanent: {
      userName: {
        color: black,
        opacity: 0.5,
        textAlign: 'left',
        padding: '10px 10px 0',
      },
      accountNumber: {
        color: black,
        opacity: 0.5,
        textAlign: 'left',
        paddingLeft: '10px',
      },
    },
    Bonus: {
      bonusWrap: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flex: 1,
        padding: '10px 10px 0',
      },
      bonusTitle: {
        color: text4,
        marginRight: 10,
      },
      bonusValue: {
        color: primary,
      },
      bonusWrapMobile: {
        display: 'flex',
        alignItems: 'center',
      },
      bonusTitleMobile: {
        fontWeight: 300,
      },
      bonusValueMobile: {
        color: primary,
        fontWeight: 'bold',
        marginLeft: margins.md,
      },
    },
    AccountPageMenu: {
      userMenuItem: {
        padding: [margins.md, margins.md, margins.md, 0],
        color: text4,
        fontWeight: 300,
        '&:hover': {
          color: text4,
          opacity: 0.4,
        },
      },
    },
    AccountPageMenuDesktop: {
      userMenu: {
        height: '100%',
        background: primary,
      },
      userMenuItem: {
        color: white,
        background: primary,
        padding: [margins.xm, margins.lg],
        '&.active, &:hover': {
          background: warning,
        },
      },
    },
  };
};
