// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets60001: JSSThemeDefaultMyBetsType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { text1Bg1 },
      colorTypes: { background3, warning, primary, text4, background1 },
    },
    margins,
  } = themeVars;
  return {
    MyBets: {
      content: {
        border: `none`,
      },
      myBetsTabsItem: {
        background: warning,
        color: '#fff',

        '&:hover, &.active': {
          background: '#2ED1AA',
        },
      },
    },
    ConfirmationBox: {
      confirmation: {
        background: 'transparent',
        marginTop: margins.md,
        borderRadius: 0,
        // border: `1px solid ${background3}`,
      },
      btnYes: {
        background: '#2354D2',
        '&:hover': {
          background: '#376FFF',
        },
      },
      btnNo: {
        background: background1,
        color: text4,
        '&:hover': {
          background: background1,
          color: text4,
          opacity: 0.8,
        },
      },
    },
    CashOut: {
      success: {
        ...text1Bg1,
        border: `1px solid ${background3}`,
      },
    },
    CashoutButton: {
      root: {
        margin: [margins.md, 0, 0],
      },
      cashoutButton: {
        background: primary,
        '&:hover': {
          background: '#376FFF',
        },
      },
    },
  };
};
