import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu60001: JSSThemeDefaultSportsMenuType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { white, transparent, primary, warning },
      colorCombinations: { whiteAccent, whitePrimary, text2Bg2 },
    },
  } = themeVars;
  return {
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
        '&.active': {
          color: warning,
        },
      },
      sportName: {
        color: white,
        '&.active': {
          color: warning,
        },
      },
    },
    HorizontalSportsListUISmallIcons: {
      sportLink: {
        color: primary,
        opacity: 0.4,
        borderRight: '1px solid #4E5A6C',
        '&.active, &:hover': {
          color: primary,
          opacity: 1,
        },
      },
    },
    HorizontalSportsListUIBigIcons: {
      list: {
        background: primary,
      },
      sportIcon: {
        color: white,
        '&.active': {
          color: warning,
        },
      },
      sportLink: {
        color: primary,
        fontWeight: 'bold',
      },
    },
    SportsMenuMobile: {
      regions: {
        background: transparent,
        padding: [0, margins.md],
      },
      region: {
        ...text2Bg2,
        fontWeight: 'bold',
        borderRadius: '2px',
        marginRight: margins.md,
      },
    },
    HorizontalSportsTabs: {
      rootSmallIcons: {
        margin: 0,
      },
    },
    SportsMenuDesktop: {
      header: {
        color: white,
      },
      sport: {
        background: primary,
        '&:hover, &:focus, &.active': {
          background: warning,
        },
      },
      sportTitle: {
        fontWeight: 'normal',
      },
      region: {
        ...whiteAccent,
        background: primary,
        '&:hover, &:focus, &.active': {
          background: warning,
        },
      },
    },
    HorizontalSportsListIconSmall: {
      sportName: {
        fontWeight: 'normal',
      },
    },
    SubMenuListUIMobile: {
      heading: {
        fontSize: 14,
        color: white,
        padding: [margins.md, margins.xm, margins.xs],
        opacity: 1,
      },
      link: {
        color: white,
        '&:hover,&:focus': {
          color: white,
        },
        '&.active': {
          ...whitePrimary,
        },
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        color: warning,
        background: white,
      },
      title: {
        color: white,
      },
    },
  };
};
