// @flow
import type { JSSThemeDefaultResetsType } from '../JSSThemeDefaultResets';
import { getButtonColors } from '../jss-helpers';

export const JSSTheme60001Resets: JSSThemeDefaultResetsType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { whiteBg3 },
    },
  } = themeVars;
  return {
    button: {
      borderRadius: '2px',
      fontWeight: 'bold',
      ...getButtonColors(whiteBg3),
    },
  };
};
