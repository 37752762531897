// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';
import { applyOnlyMobile, applyOnlyDesktop } from '../jss-helpers';

export const JSSThemeCasino60001: JSSThemeDefaultCasinoType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { white, primary, warning, text5 },
    },
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategories: {
        background: primary,
        overflowX: 'auto',
        position: 'static',
      },
      menuItem: {
        padding: [0, margins.xm],
        fontWeight: 'normal',
        borderRight: 'none',
        color: white,
        '&.active, &:hover': {
          background: warning,
          color: white,
        },
        ...applyOnlyDesktop({
          '&:hover': {
            background: warning,
            color: white,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            color: white,
          },
        }),
      },
      iconChevron: {
        fontSize: fontSizes.xs,
        marginLeft: margins.xs,
      },
    },
    CasinoContainer: {
      providersFiltersWrap: {
        color: white,
        background: warning,
      },
      providersFilters: {
        '&.active': {
          color: warning,
          background: white,
          opacity: 1,
          transition: 'all 0.2s ease',
        },
        ...applyOnlyDesktop({
          '&:hover': {
            color: warning,
            background: white,
            opacity: 1,
            transition: 'all 0.2s ease',
          },
        }),
      },
    },
    SearchBox: {
      inputWrap: {
        background: '#fff',
        height: 32,
        margin: 'auto 10px auto auto',
        color: 'rgba(78, 90, 108, 0.5)',
      },
      inputSearch: {
        color: 'rgba(78, 90, 108, 0.7)',
        '&::placeholder': {
          color: 'rgba(78, 90, 108, 0.5)',
        },
      },
    },
    CasinoGameLink: {
      rootReal: {
        background: warning,
        '&:hover': {
          background: text5,
        },
      },
      rootFun: {
        background: warning,
        '&:hover': {
          background: text5,
        },
      },
    },
  };
};
