// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll60001: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, primary },
    },
  } = themeVars;
  return {
    footerWrap: {
      background: white,
      color: primary,
    },
    licenseWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    logoImg: {
      paddingRight: 30,
    },
    newStyle: {
      paddingBottom: 20,
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    logoSection: {
      width: '100%',
      borderBottom: '1px solid rgba(35, 84, 210, 0.3)',
      display: 'flex',
      justifyContent: 'center',
    },
    logoSvg: {
      fill: primary,
      width: 80,
    },
    sponsorWrapper: {
      display: 'none',
    },
    link: {
      color: primary,
      opacity: 0.6,
      textTransform: 'capitalize',
      '&:hover, &:active, &:focus': {
        color: primary,
        opacity: 0.8,
      },
    },
    line: {
      background: 'rgba(35, 84, 210, 0.3)',
    },
    social: {
      color: primary,
      '&:hover, &:active, &:focus': {
        color: primary,
        opacity: 0.8,
      },
    },
    licenseLink: {
      color: primary,
      paddingRight: 0,
      textDecoration: 'underline !important',
      '&:hover, &:active, &:focus': {
        color: primary,
        opacity: 0.8,
      },
    },
  };
};
