// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile60001: JSSThemeDefaultHeaderMobileType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, primary },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      menuLeft: {
        background: primary,
      },
      headerContainer: {
        background: white,
        color: '#2354D2',
      },
    },
  };
};
