// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  getColorCombinations,
  type ColorTypes,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, getButtonColors } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip60001';
import { JSSThemeMyBets60001 } from './JSSThemeMyBets60001';
import { JSSThemeSportsMenu60001 } from './JSSThemeSportsMenu60001';
import { JSSThemeHeaderMenu60001 } from './JSSThemeHeaderMenu60001';
import { JSSThemeForm60001 } from './JSSThemeForm60001';
import { JSSThemeBonuses60001 } from './JSSThemeBonuses60001';
import { JSSThemeBannerItems60001 } from './JSSThemeBannerItems60001';
import { JSSThemeAuthForm60001 } from './JSSThemeAuthForm60001';
import { JSSThemeHeaderMobile60001 } from './JSSThemeHeaderMobile60001';
import { JSSThemeLanguageSwitcher60001 } from './JSSThemeLanguageSwitcher60001';
import { JSSThemeOutcomes60001 } from './JSSThemeOutcomes60001';
import { JSSThemeEventSlider60001 } from './JSSThemeEventSlider60001';
import { JSSThemeCoupons60001 } from './JSSThemeCoupons60001';
import { JSSThemeSearch60001 } from './JSSThemeSearch60001';
import { JSSThemeDropdownMenu60001 } from './JSSThemeDropdownMenu60001';

import { JSSThemeHeader60001 } from './JSSThemeHeader60001';
import { JSSThemeBalance60001 } from './JSSThemeBalance60001';
import { JSSThemePopularLeagues60001 } from './JSSThemePopularLeagues60001';
import { JSSTheme60001Resets } from './JSSTheme60001Resets';
import { JSSThemeCasino60001 } from './JSSThemeCasino60001';
import { JSSThemeVirtuals60001 } from './JSSThemeVirtuals60001';
import { JSSThemeAccountSection60001 } from './JSSThemeAccountSection60001';
import { JSSThemePromotions60001 } from './JSSThemePromotions60001';
import { JSSThemeSitemap60001 } from './JSSThemeSitemap60001';
import { JSSThemeLive60001 } from './JSSThemeLive60001';
import { JSSThemeIPools60001 } from './JSSThemeIPools60001';
import { JSSThemeAffiliates60001 } from './JSSThemeAffiliates60001';
import { JSSThemeFooterAll60001 } from './JSSThemeFooterAll60001';
import { JSSThemeTooltip60001 } from './JSSThemeTooltip60001';

export const colorTypes60001: ColorTypes = {
  ...colorTypes,
  primary: '#2354D2',
  text1: '#494949',
  text2: '#222',
  text3: '#6e6e6e',
  text4: '#4E5A6C',
  text5: '#2ED1AA',
  background1: '#E1E1E1',
  background2: '#fff',
  background3: '#8ecb65',
  background4: '#006b00',
  background5: 'rgba(1, 115, 9, 0.8)',
  background6: '#376FFF',
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#2354D2',
  // success: '',
  error: '#b33322',
  error2: '#D82E2E',
  // error3: '',
  warning: '#1EB691',
  /** This color used only on Ipools, if your task not related with Ipools, don't touch this color*/
  inform: '#4edfbc',
  neutral1: '#ddd',
  neutral2: '#b7db94',
  neutral3: '#66ff00',
  neutral4: '#2c881f',
  // border1: '',
  // border2: '',
};

const pick = ({
  primaryBg2,
  whitePrimary,
  text2Primary,
  text2White,
  text1Bg2,
  text2Bg2,
  whiteBg2,
  whiteBg3,
  text2Neutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral1,
  whiteBg4,
  whiteText2,
  whiteError,
  text1Bg1,
  text1White,
  bg1Primary,
}) => ({
  primaryBg2,
  whitePrimary,
  text2Primary,
  text2White,
  text1Bg2,
  text2Bg2,
  whiteBg2,
  whiteBg3,
  text2Neutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral1,
  whiteBg4,
  whiteText2,
  whiteError,
  text1Bg1,
  text1White,
  bg1Primary,
});

export const colorCombinations60001 = getColorCombinations(
  colorTypes60001,
  pick
);

export const JSSThemeVariables60001: JSSThemeVariablesShapeType = {
  fonts: {
    default: {
      name: 'osg-roboto',
      fontFamily: 'osg-roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'roboto/roboto-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
        {
          path: 'roboto/roboto-italic-webfont',
          fontWeight: 'normal',
          fontStyle: 'italic',
        },
        {
          path: 'roboto/roboto-bolditalic-webfont',
          fontWeight: 'bold',
          fontStyle: 'italic',
        },
      ],
    },
  },
  colors: {
    colorTypes: colorTypes60001,
    colorCombinations: colorCombinations60001,
  },
};

export const JSSTheme60001: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorTypes: {
        background1,
        background5,
        white,
        primary,
        text5,
        background6,
        error2,
        error,
        text4,
        warning,
      },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    header: JSSThemeHeader60001(themeVars),
    headerMenu: JSSThemeHeaderMenu60001(themeVars),
    search: JSSThemeSearch60001(themeVars),
    dropdownMenu: JSSThemeDropdownMenu60001(themeVars),
    mybets: JSSThemeMyBets60001(themeVars),
    popularLeagues: JSSThemePopularLeagues60001(themeVars),
    ipools: JSSThemeIPools60001(themeVars),
    tooltip: JSSThemeTooltip60001(themeVars),
    Heading: {
      common: {
        '@media (pointer: coarse)': {
          margin: '0 -5px 0 -5px',
        },
        '&.headingMobile': {
          color: '#00A77E',
          padding: [5, 20, 5],
          fontSize: 18,
        },
      },
    },
    LiveCalendar: {
      common: {
        margin: '0',
      },
      h2: {
        background: background1,
        color: '#00A77E',
        fontSize: 20,
        fontWeight: 700,
      },
      dateTitle: {
        color: text4,
        background: background1,
      },
      dateEvent: {
        color: primary,
        background: background1,
        '&:hover': {
          color: white,
          background: warning,
        },
        '&>a': {
          color: 'inherit',
        },
      },
      link: {
        color: 'inherit',
      },
    },
    Button: {
      root: {
        borderRadius: '2px',
        fontWeight: 'bold',
      },
      default: {
        color: white,
        background: primary,
        '&:hover': {
          color: white,
          background: background6,
        },
      },
      primary: {
        color: white,
        background: warning,
        '&:hover': {
          background: text5,
        },
      },
      secondary: {
        color: white,
        background: primary,
        '&:hover': {
          color: white,
          background: background6,
        },
        '&:disabled': {
          '&:hover': {
            color: white,
            background: primary,
          },
        },
      },
      accent: {
        color: white,
        background: warning,
        borderRadius: 3,
        fontWeight: 'normal',
        '&:hover': {
          background: text5,
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: '0 !important',
        lineHeight: 'normal !important',
        height: '32px !important',
      },
      linkAccent: {
        color: error2,
        fontWeight: 'normal',
        ...applyOnlyDesktop({
          '&:hover': {
            color: error,
          },
        }),
      },
    },
    betslip: betslip(themeVars),
    NoBetsMessage: {
      root: {
        color: text4,
        fontWeight: 'normal',
        background: white,
      },
    },
    sportsMenu: JSSThemeSportsMenu60001(themeVars),
    form: JSSThemeForm60001(themeVars),
    authForm: JSSThemeAuthForm60001(themeVars),
    IconCircle: {
      root: {
        background: primary,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        color: text4,
        boxShadow: `0px -1px ${white}`,
        background: '#ececec',
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        marginTop: margins.md,
        background: 'transparent',
        padding: margins.md,
      },
      stakeTitle: {
        marginTop: 0,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        margin: 10,
        padding: 0,
        background: white,
        borderRadius: 0,
        '&:not(:last-child)': {
          borderBottom: '1px solid #4E5A6C',
        },
      },
    },
    OfflineMessage: {
      popup: {
        color: 'black',
      },
    },
    PageNotFound: {
      pageNotFoundRoot: {
        color: white,
        background: background5,
      },
    },
    DropDownUI: {
      title: {
        color: primary,
      },
    },
    // Geoblock: {
    //   logoGeoblock: {
    //     background: primary,
    //   },
    // },
    affiliates: JSSThemeAffiliates60001(themeVars),
    banners: JSSThemeBannerItems60001(themeVars),
    bonuses: JSSThemeBonuses60001(themeVars),
    headerMobile: JSSThemeHeaderMobile60001(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher60001(themeVars),
    outcomes: JSSThemeOutcomes60001(themeVars),
    eventSlider: JSSThemeEventSlider60001(themeVars),
    coupons: JSSThemeCoupons60001(themeVars),
    balance: JSSThemeBalance60001(themeVars),
    resets: JSSTheme60001Resets(themeVars),
    casino: JSSThemeCasino60001(themeVars),
    live: JSSThemeLive60001(themeVars),
    virtuals: JSSThemeVirtuals60001(themeVars),
    promotions: JSSThemePromotions60001(themeVars),
    sitemap: JSSThemeSitemap60001(themeVars),
    accountSection: JSSThemeAccountSection60001(themeVars),
    footerAll: JSSThemeFooterAll60001(themeVars),
  };
};

export const getJSSThemePatterns60001: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { whitePrimary, whiteAccent, whiteBg3 },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(whitePrimary),
      secondary: getButtonColors(whiteBg3),
      accent: getButtonColors(whiteAccent),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
