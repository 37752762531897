import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection60001: JSSThemeDefaultAccountSectionType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background1, warning, text4, text5 },
    },
  } = themeVars;
  return {
    BetsHistory: {
      BetsHistoryHeading: {
        color: warning,
      },
      filtersRoot: {
        color: text4,
      },
      noBets: {
        color: text4,
      },
      filterSelectionItem: {
        background: warning,
        '&:hover': {
          background: text5,
        },
      },
    },
    AccountPageDesktop: {
      accountPageRightContent: {
        background: background1,
        boxShadow: '3px 0px 10px rgba(0, 0, 0, 0.1)',
      },
    },
    OPMPageComponent: {
      heading: {
        margin: [10, 0, 10, 10],
        color: '#00A77E',
        fontWeight: 400,
      },
    },
    OPMProvider: {
      providerRoot: {
        color: text4,
      },
    },
    OPMForms: {
      limits: {
        color: text4,
      },
      submitButton: {
        background: '#2354D2 !important',
        '&:hover': {
          background: '#376FFF !important',
          opacity: 0.7,
        },
        '&:disabled': {
          '&:hover': {
            background: '#2354D2 !important',
            opacity: 0.4,
          },
        },
      },
    },
  };
};
