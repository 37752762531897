// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm60001: JSSThemeDefaultAuthFormType = (
  themeVars
) => {
  const {
    margins,
    borderRadius,
    colors: {
      colorTypes: { text2, white, background1, text4, primary, background6 },
    },
  } = themeVars;
  return {
    AuthFormDesktop: {
      depositWrap: {
        padding: [margins.xs, margins.md],
        background: white,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
        fontWeight: 'bold',
      },
    },
    DepositButton: {
      link: {
        color: primary,
        '&:hover': {
          color: background6,
        },
      },
      iconWrap: {
        marginRight: margins.md,
      },
    },
    LoginFormDesktop: {
      popUpWrapper: {
        background: background1,
        boxShadow: 'none',
        '&:before': {
          background: background1,
        },
      },
    },
    MobileAuthForm: {
      wrapper: {
        color: text2,
      },
      label: {
        color: text4,
      },
      link: {
        color: text4,
      },
    },
    LoginPage: {
      heading: {
        color: text4,
      },
    },
    PasswordResetWithEmail: {
      resetPassword: {
        color: primary,
      },
      resetPasswordDescription: {
        color: primary,
      },
      passwordResetDebounce: {
        color: primary,
        '@media (pointer: coarse)': {
          color: text4,
        },
      },
    },
  };
};
