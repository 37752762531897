// @flow
import { type JSSThemeDefaultSearchType } from '../JSSThemeDefaultSearch';

export const JSSThemeSearch60001: JSSThemeDefaultSearchType = (themeVars) => {
  const {
    colors: {
      colorTypes: { text1, text2, text5, neutral1, primary, white },
    },
  } = themeVars;
  return {
    SearchBar: {
      searchContainer: {
        background: '#F5F5F5',
      },
      input: {
        background: '#F5F5F5',
      },
      iconContainer: {
        color: 'rgba(78, 90, 108, 0.5)',
      },
    },
    SportsbookSearchResult: {
      sportsbookSearchResultRoot: {
        padding: 0,
      },
      title: {
        color: text1,
      },
      description: {
        color: text2,
        height: '37px',
        lineHeight: '29px',
        '&:hover': {
          color: text2,
          opacity: 0.5,
        },
      },
      sportsbookSearchResultOutcomesWrap: {
        borderTop: `1px solid ${neutral1}`,
      },
      marketCount: {
        background: primary,
        fontWeight: 'bold',
        color: white,
        '&:hover': {
          color: white,
          background: text5,
        },
      },
    },
  };
};
