// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes60001: JSSThemeDefaultOutcomesType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { primary, background1, white, warning, text3 },
    },
  } = themeVars;
  return {
    MobileOutcome: {
      '@global': {
        '@keyframes blinkUp': {
          '0%': { backgroundColor: '#02bd2e' },
          '50%': { backgroundColor: background1 },
          '100%': { backgroundColor: '#02bd2e' },
        },
        '@keyframes blinkDown': {
          '0%': { backgroundColor: '#ff2424' },
          '50%': { backgroundColor: background1 },
          '100%': { backgroundColor: '#ff2424' },
        },
      },
      priceValue: {
        color: white,
        '&.active': {
          color: white,
        },
      },
      root: {
        color: white,
        background: primary,
        '&.active': {
          background: warning,
        },
      },
      wideRoot: {
        background: primary,
      },
      rootTrendUp: {
        '&::before': {
          borderColor: background1,
        },
      },
      rootTrendDown: {
        '&::after': {
          borderColor: background1,
        },
      },
    },
    Outcome: {
      root: {
        height: 30,
        lineHeight: '30px',
        color: white,
        background: primary,
        '&.active': {
          background: warning,
        },
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            background: warning,
          },
        },
        '&.disabled': {
          background: text3,
          color: '#fff',
          opacity: '.5',
        },
        value1x2: {
          opacity: 0.4,
          '&.active': {
            opacity: 0.8,
          },
        },
      },
    },
  };
};
