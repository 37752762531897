// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu60001: JSSThemeDefaultDropdownMenuType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { text2, white },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      item: {
        '&:hover, &:focus, &.active': {
          background: white,
        },
      },
    },
    dropdownMobile: {
      select: {
        color: text2,
      },
      description: {
        color: text2,
      },
      chevron: {
        color: text2,
      },
    },
  };
};
