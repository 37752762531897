// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons60001: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    borderRadius,
    colors: {
      colorTypes: { warning, white, primary, text5, text4 },
    },
  } = themeVars;
  return {
    SportTabs: {
      tab: {
        color: '#fff',
        background: primary,
        '&:hover': {
          color: '#fff',
        },
      },
      activeTab: {
        color: '#fff',
        background: warning,
      },
    },
    Market: {
      root: {
        borderRadius: borderRadius.sm,
        borderTop: 'none',
      },
      date: {
        color: text4,
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        borderRadius: borderRadius.sm,
        height: 30,
        lineHeight: '30px',
        background: warning,
        color: white,
        fontWeight: 'bold',
        '&:hover': {
          background: text5,
          color: '#fff',
        },
      },
    },
    Betbuilder: {
      toggler: {
        '&:hover': {
          background: primary,
        },
      },
      exampleContainer: {
        background: white,
        color: text4,
      },
    },
    MobileWidgetCouponView: {
      sportHeader: {
        color: primary,
      },
      sportInnerLink: {
        color: primary,
      },
      sportLinkIcon: {
        color: text4,
      },
    },
    EventPrimaryMarketOutcomesMobile: {
      date: {
        color: text4,
      },
      teamItem: {
        color: text4,
      },
      eventLinkIcon: {
        color: text4,
      },
    },
    WidgetSportMobile: {
      league: {
        color: primary,
      },
    },
    AllLeagues: {
      filterIcon: {
        fill: text4,
      },
      league: {
        color: text4,
      },
    },
  };
};
