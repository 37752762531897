// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60001,
  JSSThemeVariables60001,
  getJSSThemePatterns60001,
} from './JSSTheme60001';
import { GeoblockPage60001 } from './GeoblockPage60001';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullDesktop60001').then((module) => module.FullDesktop60001),
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile60001').then((module) => module.FullMobile60001),
  }),
  JSSTheme: JSSTheme60001,
  JSSThemeVariables: JSSThemeVariables60001,
  JSSThemePatterns: getJSSThemePatterns60001,
  GeoblockPage: GeoblockPage60001,
});
