// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm60001: JSSThemeDefaultFormType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { text1White },
      colorTypes: { neutral1, error2, text4, white, background1 },
    },
    margins,
    fontSizes,
    borderRadius,
  } = themeVars;
  return {
    Input: {
      hasError: {
        borderColor: error2,
        color: error2,
      },
      root: {
        border: `1px solid #c6c6c6`,
        borderRadius: borderRadius.lg,
        lineHeight: 'auto',
        height: 'auto',
        padding: [10, 10, 10, 14],
        ...text1White,
        '&::placeholder': {
          color: neutral1,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${white} inset !important`,
          '-webkit-text-fill-color': `${text4} !important`,
        },
      },
      betslipInput: {
        border: 'none',
        borderRadius: 0,
        ...text1White,
        background: background1,
        '&::placeholder': {
          color: text4,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${background1} inset !important`,
          borderColor: `${background1} !important`,
          '-webkit-text-fill-color': `${text4} !important`,
        },
        '@media (pointer: coarse)': {
          background: white,
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 30px ${white} inset !important`,
            borderColor: `${white} !important`,
          },
        },
      },
    },
    Field: {
      root: {
        color: 'black',
        marginBottom: margins.xm * 2,
      },
      fieldLabel: {
        fontSize: fontSizes.lg,
        display: 'block',
        padding: [0, 0, margins.xs],
        color: text4,
      },
      fieldLabelError: {
        color: error2,
      },
    },
  };
};
