// @flow
import { type JSSThemeDefaultTooltipType } from '../JSSThemeDefaultTooltip';

export const JSSThemeTooltip60001: JSSThemeDefaultTooltipType = (themeVars) => {
  // const {
  //   colors: {
  //     colorCombinations: { background1 },
  //   },
  // } = themeVars;
  return {
    Tooltip: {
      tooltipText: {
        background: '#e1e1e1',
        color: '#4E5A6C',
        fontWeight: '400',

        '&:before': {
          background: '#e1e1e1',
        },
      },
    },
  };
};
