// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive60001: JSSThemeDefaultLiveType = (themeVars) => {
  const {
    colors: {
      colorTypes: { white, primary },
    },
  } = themeVars;
  return {
    LiveMenuEvent: {
      LiveIcon: {
        fill: primary,
      },
      LiveEventRanked: {
        background: white,
      },
    },
    LiveMenuEventHeading: {
      LiveIcon: {
        fill: primary,
        '@media (pointer: coarse)': {
          fill: primary,
        },
      },
    },
    LiveMenuRankedEvent: {
      LiveIcon: {
        fill: primary,
      },
    },
  };
};
