// @flow
import { type JSSThemeDefaultBetslipType } from '../JSSThemeDefaultBetslip';

export const JSSThemeBetslip: JSSThemeDefaultBetslipType = (themeVars) => {
  const {
    colors: {
      colorTypes: {
        background1,
        error,
        primary,
        warning,
        error2,
        white,
        text4,
      },
    },
  } = themeVars;
  const BetslipRoot = {
    background: white,
  };
  return {
    Betslip: {
      root: BetslipRoot,
      linkButtons: {
        background: 'transparent',
      },
      info: {
        background: 'transparent',
      },
      acceptButtonBetslip: {
        '& > button': {
          backgroundColor: primary,
        },
      },
    },
    BetslipTotals: {
      root: {
        borderTop: 'none',
      },
      container: {
        color: text4,
      },
    },
    BetslipSelection: {
      common: {
        background: white,
        borderRadius: '2px',
        '@media (pointer: coarse)': {
          background: background1,
          borderRadius: '3px',
        },
      },
    },
    BetslipErrors: {
      list: {
        borderRadius: 0,
        color: error2,
        background: white,
      },
      readMoreLink: {
        color: '#d82e2e',
        '&.active, &:hover': {
          color: '#d82e2e',
        },
      },
    },
    SingleSelectionInfo: {
      eventDescription: {
        color: text4,
      },
      marketDescription: {
        color: text4,
      },
      priceDescription: {
        color: text4,
      },
      liveLabel: {
        borderRadius: 0,
        background: warning,
      },
      remove: {
        color: text4,
      },
    },
    SingleSelectionStaticInfo: {
      priceDescription: {
        paddingBottom: 4,
        borderBottom: '1px solid #4E5A6C',
      },
    },
    BetslipInput: {
      container: {
        '@media (pointer: coarse)': {
          background: '#4E5A6C',
          color: white,
          borderRadius: '3px 3px 0 0',
        },
      },
      label: {
        '@media (pointer: coarse)': {
          color: white,
        },
      },
    },
    BetslipInputs: {
      stake: {
        borderRadius: '2px',
      },
    },
    SelectionState: {
      root: {
        borderRadius: 3,
        color: '#D82E2E',
        background: '#E1E1E1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        lineHeight: 1,
        width: 'max-content',
        padding: '2px 4px',
      },
      notSingle: {
        color: error,
      },
    },
    ReceiptSelectionAmounts: {
      root: {
        background: background1,
        border: `1px solid ${primary}`,
        borderRadius: 0,
      },
    },
    Receipt: {
      root: BetslipRoot,
      titleContainer: {
        borderRadius: 0,
        background: warning,
      },
      controls: {
        background: white,
      },
    },
    ShareBets: {
      message: {
        '&.shown': {
          color: '#D82E2E',
          paddingTop: '7px',
        },
      },
    },
    BetslipPanel: {
      tooltipIcon: {
        '&:before, &:after': {
          backgroundColor: background1,
        },
        '&:after': {
          color: text4,
          backgroundColor: background1,
        },
      },
    },
  };
};
