// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu60001: JSSThemeDefaultHeaderMenuType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { primary, warning, white },
    },
  } = themeVars;
  const menuItemAfterElement = {
    content: '""',
    position: 'absolute',
    textAlign: 'center',
    bottom: '-27px',
    left: '6%',
    width: '90%',
    borderBottom: `3px solid ${warning}`,
  };
  return {
    HeaderMenuMobile: {
      mobileMenu: {
        background: 'transparent',
      },
      menuItem: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        color: white,
        '&:hover,&:focus': {
          color: white,
        },
        '&.active': {
          color: warning,
        },
      },
    },
    HeaderMenuDesktop: {
      menuWrapper: {
        background: 'none',
        fontWeight: 'normal',
      },
      menuItemWrapper: {
        borderRight: `1px solid ${primary}`,
      },
      menuItem: {
        color: primary,
        fontWeight: 'bold',
        '&:hover, &:focus': {
          color: warning,
          '&:after': menuItemAfterElement,
        },
      },
      menuItemActive: {
        color: primary,
        '&:after': menuItemAfterElement,
      },
    },
  };
};
