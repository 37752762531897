// @flow
import { type JSSThemeDefaultLanguageSwitcherType } from '../JSSThemeDefaultLanguageSwitcher';

export const JSSThemeLanguageSwitcher60001: JSSThemeDefaultLanguageSwitcherType =
  (themeVars) => {
    const {
      colors: {
        colorTypes: { text2, white, text4 },
      },
    } = themeVars;
    return {
      LanguageSelectComponent: {
        languageItem: {
          whiteSpace: 'nowrap',
          color: white,
        },
        languageSelectWrap: {
          color: text4,
        },
      },
      LanguageSelectComponentMobile: {
        select: {
          color: text2,
        },
        languageDescription: {
          color: text2,
        },
        languageChevron: {
          color: text2,
        },
      },
    };
  };
