// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeIPools60001 = (themeVars: typeof JSSThemeVariables) => {
  const {
    margins,
    colors: {
      colorTypes: {
        error,
        primary,
        neutral1,
        text4,
        white,
        text2,
        text3,
        text5,
        background6,
        inform,
        warning,
        accent,
        background1,
      },
    },
  } = themeVars;
  return {
    IPoolsBanners: {
      banner: {
        textShadow: '',
      },
    },
    IPools: {
      upcomingPools: {
        background: primary,
      },
      root: {
        background: 'transparent',
      },
      howToLinkManual: {
        backgroundColor: background6,
        background:
          'url(https://cdn.rojabet.com/images/ipools-src/bg-info-top.svg) no-repeat right bottom',
      },
      howToLinkVideo: {
        backgroundColor: background6,
        background:
          'url(https://cdn.rojabet.com/images/ipools-src/bg-info-bottom.svg) no-repeat right top',
      },
      event: {
        backgroundColor: background6,
        color: 'white !important',
        background:
          'url(https://cdn.rojabet.com/images/ipools-src/trophy-bg.svg) no-repeat right bottom',
      },
      topBarIconLive: {
        color: inform,
      },
      eventLive: {
        color: inform,
      },
      winBigJackpotLabel: {
        color: inform,
      },
      enteredLabel: {
        color: inform,
        boxShadow: `0px 0px 0px 1.5px ${inform}`,
        textAlign: 'end',
        minWidth: 'auto',
      },
      eventInfoLabel: {
        '&.isBigJackpot': {
          color: inform,
        },
      },
      winBigJackpotIcon: {
        color: inform,
      },
    },
    IPoolsSettled: {
      rootSettled: {
        background: 'transparent',
        color: text2,
      },
      event: {
        '@media (pointer: coarse)': {
          backgroundColor: text3,
        },
        backgroundColor: text3,
        color: 'white !important',
        background:
          'url(https://cdn.rojabet.com/images/ipools-src/trophy-bg-lite.svg) no-repeat right bottom',
      },
      eventSelected: {
        boxShadow: `inset 0px 0px 0px 3px #1db691`,
      },
      winBigJackpotLabel: {
        color: inform,
      },
      winBigJackpotIcon: {
        color: inform,
      },
      eventName: {
        opacity: 0.5,
      },
      eventInfoLabel: {
        '&.isBigJackpot': {
          color: inform,
        },
      },
      settledRoot: {
        background: 'transparent',
        color: text2,
        '&:hover': {
          color: 'rgba(34,34,34,0.8)',
        },
      },
      settledToggler: {
        color: warning,
        '&:hover': {
          color: inform,
        },
      },
    },
    Entries: {
      seatsTitle: {
        color: text4,
        '@media (pointer: coarse)': {
          color: text3,
        },
      },
      newSeat: {
        color: primary,
        border: `5px solid ${primary}`,
        '&.active': {
          '&:hover': {
            border: `5px solid ${primary}`,
            color: warning,
          },
        },
        '&:disabled': {
          cursor: 'not-allowed',
          color: text3,
          border: `5px solid ${text3}`,
          opacity: 0.5,
        },
      },
      seat: {
        background: primary,
        color: white,
        '&:hover': {
          background: warning,
        },
        '&.active': {
          background: warning,
          color: white,
        },
        '@media (pointer: coarse)': {
          background: primary,
        },
      },
    },
    EventHeader: {
      prizeFundRoot: {
        color: text4,
      },
      wrapBars: {
        color: text3,
      },
      subHeading: {
        color: text4,
        '@media (pointer: coarse)': {
          color: text4,
        },
      },
      placesMobile: {
        background: white,
      },
    },
    QuestionHeader: {
      live: {
        questionColumnsLiveOrEnded: {
          '@media (pointer: coarse)': {
            marginRight: '39px !important',
          },
        },
        questionColumn: {
          width: 92,
        },
      },
      settled: {
        questionColumn: {
          width: 92,
        },
      },
    },
    Question: {
      live: {
        questionBanker: {
          background: '#33333330',
        },
        questionTime: {
          '@media screen and (min-width: 1440px)': {
            display: 'block',
          },
        },
        bankerLabel: {
          color: primary,
          '@media screen and (min-width: 1440px)': {
            display: 'inline-block',
          },
        },
        choice: {
          '&.active': {
            background: `${warning} !important`,
            transition: 'all 0.4s',
          },
          '&:hover': {
            background: primary,
          },
        },
        choiceHoverable: {
          '@media not all and (pointer: coarse)': {
            '&:hover': {
              background: warning,
            },
          },
        },
        column: {
          color: white,
          display: 'none',
        },
        scoreValue: {
          color: white,
        },
        x2Button: {
          height: 55,
          background: '#ddd',
          '&.active': {
            background: `${warning} !important`,
          },
          '&:hover': {
            background: '#cbcbcb',
          },
          '@media screen and (min-width: 1440px)': {
            height: 38,
            margin: [0, 0, 0, margins.md],
          },
          '@media (pointer: coarse)': {
            background: '#ddd',
            color: text2,
            margin: [0, 0, 0, margins.xs],
            padding: [0, margins.md],
            '&:hover': {
              background: '#cbcbcb',
              color: text2,
            },
          },
        },
        resultLabelCheck: {
          color: warning,
        },
        mixedAnswer: {
          '@media screen and (max-width: 1020px)': {
            width: '80%',
          },
        },
        selectInput: {
          '& select': {
            background: primary,
            color: white,
          },
          '&.active': {
            '& select': {
              background: warning,
            },
          },
        },
        dropdownIcon: {
          color: white,
          '&.active': {
            color: white,
          },
        },
        infoDescriptionWrapperMultiChoice: {
          '&:before': {
            backgroundColor: background1,
          },
        },
        infoDescriptionMultiChoice: {
          background: background1,
        },
        rangeInput: {
          '&.active': {
            '& input[type=range]::-webkit-slider-thumb': {
              background: warning,
            },
          },
        },
        rangeInputValue: {
          '&.active': {
            color: warning,
          },
        },
      },
      settled: {
        questionTime: {
          display: 'flex',
          marginTop: 2,
          '@media screen and (min-width: 1440px)': {
            display: 'block',
          },
        },
        bankerLabel: {
          marginLeft: margins.xs,
          display: 'inline-block',
          '@media screen and (min-width: 1440px)': {
            display: 'inline-block',
          },
        },
        questionLeagueMobile: {
          marginBottom: 0,
        },
        choice: {
          '&.active': {
            background: warning,
            '&:hover': {
              background: warning,
            },
          },
        },
        column: {
          display: 'none',
          color: white,
        },
        scoreValue: {
          color: white,
        },
        resultLabelCheck: {
          color: warning,
        },
        mixedAnswer: {
          '@media screen and (max-width: 1020px)': {
            width: '80%',
          },
        },
        dropdownIcon: {
          color: white,
          '&.active': {
            color: white,
          },
        },
        selectInput: {
          '& select': {
            background: primary,
            color: white,
          },
          '&.active': {
            '& select': {
              background: warning,
            },
          },
        },
        infoDescriptionWrapperMultiChoice: {
          '&:before': {
            backgroundColor: background1,
          },
        },
        infoDescriptionMultiChoice: {
          background: background1,
        },
        rangeInput: {
          '& input[type=range]::-webkit-slider-thumb': {
            background: warning,
          },
        },
        rangeInputValue: {
          color: warning,
        },
      },
    },
    IPoolsEvent: {
      rootEventHeaderPic: {
        background:
          'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 210px',
        '@media screen and (max-width: 1194px)': {
          background:
            'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 260px',
          backgroundSize: 315,
        },
        '@media screen and (min-width: 1440px)': {
          background:
            'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 180px',
        },
      },
      root: {
        '@media (pointer: coarse)': {
          background: 'transparent',
        },
      },
      showAllToggler: {
        color: text4,
      },
      backLink: {
        color: text3,
      },
      rootEvent: {
        '@media (pointer: coarse)': {
          background: 'transparent',
        },
      },
      EventHeader: {
        color: text4,
        '@media (pointer: coarse)': {
          color: text4,
        },
      },
      questions: {
        background: white,
        color: text4,
      },
    },
    IPoolsSettledEvent: {
      backLink: {
        color: text3,
      },
      rootEvent: {
        '@media (pointer: coarse)': {
          background: 'transparent',
        },
      },
      rootEventHeaderSettled: {
        opacity: 1,
      },
      rootEventHeaderPic: {
        background:
          'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 200px',
        '@media screen and (max-width: 1178px)': {
          background:
            'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 260px',
          backgroundSize: 315,
        },
        '@media screen and (min-width: 1440px)': {
          background:
            'url(https://cdn.processingservices.biz/casino/upload_4e6c38c9c8d608755f357ab4241965ea.png) no-repeat 100% 170px',
          backgroundSize: 315,
        },
      },
      settledPoolMark: {
        color: primary,
      },
      questionLeftCol: {
        '@media (pointer: coarse)': {
          background: white,
        },
      },
      questions: {
        background: white,
        color: text4,
      },
    },
    IPoolsSubmit: {
      rootMobile: {
        background: primary,
      },
      rootDesktop: {
        background: neutral1,
        color: text3,
      },
      totalStake: {
        color: white,
      },
      error: {
        color: error,
        '@media (pointer: coarse)': {
          color: accent,
        },
      },
    },
    IPoolsSubmitChoice: {
      totalStake: {
        marginBottom: margins.md,
      },
    },
    IPoolsLeaderboard: {
      root: {
        background: white,
        color: text5,
        '@media (pointer: coarse)': {
          background: white,
          borderRadius: 0,
        },
      },
      row: {
        '&:first-child': {
          color: '#F8C300',
        },
      },
      playerScore: {
        color: '#F8C300',
      },
      leaderboardHeading: {
        color: warning,
      },
    },
    IPoolsBars: {
      title: {
        '@media (pointer: coarse)': {
          color: text4,
        },
      },
      link: {
        '&:hover': {
          color: 'rgba(73,73,73,0.6)',
        },
        '@media (pointer: coarse)': {
          color: text4,
        },
      },
    },
  };
};
