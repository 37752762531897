// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions60001: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { white, background2, primary, text4 },
    },
  } = themeVars;
  return {
    PromotionsComponent: {
      itemsByTypeTitle: {
        marginBottom: margins.md,
        '@media (pointer: coarse)': {
          padding: '0 15px',
          color: text4,
        },
      },
      nav: {
        paddingLeft: '15px',
        '@media (pointer: coarse)': {
          paddingLeft: '15px',
        },
      },
      selectedLink: {
        color: `${primary} !important`,
        borderBottom: `3px solid ${primary} !important`,
        '&:hover': {
          opacity: `1 !important`,
        },
      },
      promoLink: {
        color: text4,
        '&:hover, &:active': {
          color: text4,
          opacity: 0.7,
          borderBottom: `3px solid ${text4}`,
        },
      },
      itemsByType: {
        alignItems: 'stretch',
      },
    },
    PromotionsWrapper: {
      promotionsRoot: {
        '&>h1': {
          display: 'none',
        },
        '@media (pointer: coarse)': {
          '&>h1': {
            display: 'block',
          },
        },
      },
      promotionsWrapper: {
        padding: 0,
        marginTop: 0,
      },
    },
    SeasonalPromotion: {
      root: {
        margin: [0, margins.xm, margins.xm],
        background: white,
      },
      promoHeading: {
        position: 'relative',
      },
      promoHeader: {
        color: background2,
        fontSize: 30,
      },
      termsHeader: {
        color: background2,
        fontSize: 18,
      },
      buttonRoot: {
        position: 'absolute',
        background: 'transparent',
        top: 55,
        left: 'calc(50% - 75px)',
      },
    },
    PromotionUI: {
      bannerContentHeader: {
        fontSize: fontSizes.xxxxl,
        padding: [margins.md, 0, 0],
        fontWeight: 'bold',
        color: white,
      },
      itemBody: {
        backgroundPosition: 'center',
      },
      bannerContentTitle: {
        fontSize: fontSizes.xxl,
        fontWeight: 'bold',
        color: white,
      },
      itemSecondTitle: {
        marginTop: '0',
        fontSize: 24,
        color: primary,
        textAlign: 'center',
        background: white,
        padding: [margins.md, margins.md, '0'],
        '@media (pointer: coarse)': {
          fontSize: 18,
          background: '#e1e1e1 ',
          textAlign: 'left',
          padding: '10px 0 0 0',
        },
      },
      itemDescription: {
        color: text4,
        background: white,
        padding: [margins.sm, margins.md, margins.xm],
        '@media (pointer: coarse)': {
          background: '#e1e1e1 ',
          padding: '0 0 12px 0',
        },
      },
      buttonSection: {
        padding: ['0', margins.md, margins.md],
        background: white,
        alignSelf: 'flex-end',
        width: '100%',
        marginTop: 'auto',
        '@media (pointer: coarse)': {
          background: '#e1e1e1 ',
          padding: '0',
        },
      },
      promoItemContainer: {
        flexBasis: 'calc(33% - 25px)',
        width: 'calc(33% - 25px)',
        margin: [0, 12.5],
        background: white,
        display: 'flex',
        flexDirection: 'column',
        '@media screen and (min-width: 1300px)': {
          flexBasis: 'inherit',
          '&:last-child': {
            marginRight: 12.5,
          },
          '&:nth-child(odd)': {
            marginRight: 12.5,
          },
        },
        '@media screen and (min-width: 1000px) and (max-width: 1300px)': {
          flexBasis: 'calc(33% - 25px)',
        },
        '@media screen and (max-width: 1000px)': {
          flexBasis: 'calc(50% - 25px)',
          margin: [margins.md, 12.5],
        },
        '@media screen and (max-width: 700px)': {
          flexBasis: '100%',
          margin: [margins.md, 12.5],
        },
      },
    },
  };
};
