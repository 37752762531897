// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues60001: JSSThemeDefaultPopularLeaguesType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { text2, white, primary, warning },
    },
    fontSizes,
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        background: primary,
        '@media (pointer: coarse)': {
          background: primary,
        },
      },
      header: {
        fontSize: fontSizes.xxl,
        '@media (pointer: coarse)': {
          color: white,
          opacity: 1,
        },
      },
      img: {
        '@media (pointer: coarse)': {
          filter: 'none',
        },
      },

      link: {
        '&:active, &:focus': {
          color: white,
          '@media (pointer: coarse)': {
            color: text2,
          },
        },
        '@media (pointer: coarse)': {
          color: white,
        },
        '&:hover': {
          background: warning,
        },
      },
    },
  };
};
